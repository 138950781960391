<template>
	<div id="body">
		<!-- PC头部 -->
		<div class="homeheader">
			<div class="header-left">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/logo3.png" style="cursor: pointer;"
					  @click="$router.push('/')">
				<p style=" margin: 0;font-weight: bold;">大总管官方商城</p>
			</div>
			<div class="header-center">
				<div class="zt">
					<div class="tit">
						<a href="#1">首页</a>
					</div>
					<div class="tit">
						<a href="#2">软件</a>
					</div>
					<div class="tit">
						<a href="#3">行业软件</a>
					</div>
					<div class="tit">
						<a href="#4">增值服务</a>
					</div>
					<div class="tit">
						其他
					</div>
				</div>
			</div>
			<div class="header-right">
				<!-- <el-input placeholder="请输入内容" @change='search' prefix-icon="el-icon-search" v-model="selinp">
				</el-input> -->
			</div>
		</div>
		<div class="date_main">
			<div class="left">
				<pic-zoom :scale="2" :url="detail.Url"></pic-zoom>
			</div>
			<div class="right">
				<span class="titletext">
					{{ detail.Name }}
				</span>
				<div class="picbox">
					<span
						 style="display: inline-block; border: 1px solid #fd3f31; width: 60px;height: 30px;text-align: center;line-height: 30px;border-radius: 5px;margin-right: 2%;">价格</span>
					<span>￥</span>
					<span style="font-size: 70px;">{{ detail.Price }}.00<span
						 style="font-size: 14px; color: #888888;"> 起</span></span>
				</div>
				<div class="verbox">
					<p>选择版本</p>
					<button type="button">1年版</button>
					<el-input-number v-model="num" :max="1" :min="1" class="numbox" @change="handleChange"></el-input-number>
				</div>
				<div class="vesboxs">
					<span>{{ detail.Name }} 1年版</span>
					<span>价格 <span style="color: #fd3f31;font-size: 28px;">{{ detail.Price }}元<span
						 style="font-size: 14px; color: #888888;"> 起</span></span></span>
				</div>
				<div id="btn">
					<button type="button" @click="payfun">立即购买</button>
				</div>
			</div>
		</div>
		<div class="tex">
			<div class="top">
				<span>
					<i class="el-icon-circle-check"></i>大总管自营
				</span>
				<span>
					<i class="el-icon-circle-check"></i>7x12小时 客服服务
				</span>
			</div>
			<div class="bottom">
				对于购买 <span style="color: #000000;"> 大总管科技软件 </span> 有任何疑问？<span
				 style="color: #fd3f31;cursor: pointer;">咨询人工客服 ></span>
			</div>
		</div>
	</div>
</template>

<script>
import PicZoom from 'vue-piczoom'
const axi = require('../../assets/axi.js')
export default {
	name: 'App',
	components: {
		PicZoom
	},
	data() {
		return {
			detail: {},
			prlist: [],
			num: 1
		}
	},
	created() {
		console.log(this.$store.state.shopid)
		this.detail = this.$store.state.shopid
	},
	methods: {
		handleChange(value) {
			console.log(value);
		},
		payfun() {
			console.log(this.detail)
			axi.post('Commodity/CommodityScaleDetails?Type=' + this.detail.Type).then(res => {
				console.log(res)
				if (res.data.Code == 1000) {
					this.detail.Slave = res.data.Dto
					this.$store.commit('shopidfun', this.detail)
					this.$router.push('/information')
				} else {
					this.$message.error(res.data.Msg)
				}
			})
			// this.$store.commit('shopidfun',val)
			// this.$router.push('/information')
		}
	}
}
</script>

<style lang="less" scoped>
#body {
	background-color: #F4F5FA;
	min-height: 100vh;
}

.homeheader {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	position: sticky;
	position: -webkit-sticky;
	top: 0px;
	background-color: #FFFFFF;
	z-index: 999;
	
	.header-left {
		width: 20%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		img {
			height: 100%;
			max-width: 60%;
		}
	}
	
	.header-center {
		width: 60%;
		height: 100%;
		
		.zt {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			
			.tit {
				width: 20%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				font-size: 18px;
				
				a {
					text-decoration: none;
					color: #000;
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				
				a:hover {
					color: #fd3f31;
				}
			}
			
			.tit:hover {
				color: #fd3f31;
				border-bottom: 3px solid #fd3f31;
			}
		}
	}
	
	.header-right {
		width: 20%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.el-input {
			width: 54%;
		}
	}
}

.date_main {
	width: 80%;
	margin-left: 10%;
	height: 600px;
	margin-top: 3%;
	display: flex;
	justify-content: space-between;
	align-content: center;
	
	.left {
		width: 30%;
		height: 100%;
		text-align: center;
		
		img {
			height: 70%;
			max-width: 100%;
			background-color: #ffffff;
			box-shadow: 0 14px 26px 0 rgb(19 29 61 / 15%);
		}
	}
}

.right {
	width: 65%;
	height: 100%;
	// background-color: #ffaa7f;
	padding-left: 5%;
	
	.titletext {
		font-size: 36px;
	}
	
	.picbox {
		color: #fd3f31;
	}
	
	.verbox {
		height: 25%;
		margin-top: 5%;
		
		button {
			width: 25%;
			height: 40px;
			color: #fd3f31;
			border: 1px solid #fd3f31;
			display: block;
			background-color: #fff;
		}
		
		.numbox {
			margin-top: 3%;
		}
	}
	
	.vesboxs {
		display: flex;
		width: 80%;
		height: 10%;
		margin-top: 5%;
		background-color: #FFFFFF;
		justify-content: space-around;
		align-items: center;
	}
	
	#btn {
		width: 80%;
		height: 20%;
		display: flex;
		margin-top: 5%;
		justify-content: flex-end;
		align-items: center;
		
		button {
			width: 60%;
			height: 60%;
			border-radius: 5px;
			background: linear-gradient(to right, #fd3f31, #FF8644);
			color: #fff;
			position: relative;
			overflow: hidden;
		}
		
		button:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			pointer-events: none;
			background-image: radial-gradient(circle, red 10%, transparent 10.01%);
			background-repeat: no-repeat;
			background-position: 50%;
			transform: scale(10, 10);
			opacity: 0;
			transition: transform .5s, opacity .5s;
		}
		
		button:active:after {
			transform: scale(0, 0);
			opacity: .3;
			//设置初始状态
			transition: 0s;
		}
		
	}
}

.tex {
	height: 30%;
	width: 100%;
	
	.top {
		width: 25%;
		height: 50%;
		margin-left: 10%;
		display: flex;
		color: #999999;
		font-size: 14px;
		justify-content: space-around;
		align-items: center;
		border-bottom: 1px solid #e0e1e2;
	}
	
	.bottom {
		width: 30%;
		margin-left: 5%;
		height: 50%;
		margin-top: 1%;
		font-size: 12px;
		color: #999999;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
